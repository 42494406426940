/* eslint-disable new-cap */
import React, { createContext, useEffect, useState, useMemo } from 'react';

import { getQueryParams } from '../url';
import { InitRequest } from '../axios';
import { sendMessage } from '../message';
import { load } from '../i18n/i18n';
import { useGlobalConfigStore } from 'zustandStore';

import { EVENT_TYPE_IFRAME } from '../constant';

export const InitContext = createContext();

const InitProvider = ({ children }) => {
  const [init, setInit] = useState();
  const params = getQueryParams(window.location);
  const domain = params?.domain;
  const country = params?.country;
  const lang = params?.lang;
  const isTestingMode = params?.isTestingMode;
  const isBrowserMode = params?.isBrowserMode === 'true';
  const isDoctor = params?.type === 'doctor';
  const setAppToken = useGlobalConfigStore(state => state.setAppToken);

  const getInitData = async ({ token, isDoctorSide }) => {
    const clientId = 'whitelabel-ehs-gov';
    const initReq = await InitRequest({ token, clientId, isDoctorSide });

    if (initReq) {
      setInit({ ...init, hasInit: true });
      load(country, lang);
    }
  };

  useEffect(() => {
    if (isBrowserMode) {
      // only apply when mode browser (provide for QA Testing)
      // Initialize First Load
      getInitData({
        token: null,
        isDoctorSide: isDoctor
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrowserMode, domain]);

  useEffect(() => {
    if (!isTestingMode && !isBrowserMode) {
      sendMessage({ message: { type: 'document_ready' } }, '*');
    }
  }, [isTestingMode, isBrowserMode]);

  useEffect(() => {
    const initialLoad = async event => {
      const { data = {}, origin } = event;
      const originUrl = new URL(origin);
      const queryParams = getQueryParams(window.location);
      const domainIframe = queryParams?.domain;
      const isDoctorSide = queryParams?.type === 'doctor';

      if (originUrl.hostname === domainIframe || isDoctorSide) {
        try {
          const dataMessage = data && typeof data === 'string' && JSON.parse(data || '{}');
          const token = dataMessage?.value;
          const config = dataMessage?.config;
          const clientId = dataMessage?.clientId;

          if (dataMessage && dataMessage?.type === EVENT_TYPE_IFRAME.SEND_TOKEN) {
            await getInitData({
              token,
              clientId,
              isDoctorSide
            });
            setAppToken(token);
            setInit(value => ({ ...value, config }));
          }
        } catch (err) {
          console.error(err);
        }
      }
    };

    if (!isTestingMode && !isBrowserMode) {
      window.addEventListener('message', initialLoad);

      return () => window.removeEventListener('message', initialLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTestingMode, isBrowserMode]);

  const collectionProps = useMemo(
    () => ({
      value: { init }
    }),
    [init]
  );

  return <InitContext.Provider value={collectionProps}>{children}</InitContext.Provider>;
};

export default InitProvider;
