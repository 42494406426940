import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToolboxIcon from './ToolboxIcon';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import { CheckCircle } from 'phosphor-react';
import AudioPreview from './audio-preview';
import useClickOutside from 'libs/hooks/useClickOutside';
import useDeviceErrorMessageStore from 'zustandStore/deviceErrorMessage';
import { useMeetingAppContext } from 'MeetingAppContextDef';
import { useMediaDevice } from '@videosdk.live/react-sdk';

const Toolbox = ({
  micOn,
  webcamOn,
  toggleView,
  handleToggleMic,
  handleToggleWebcam,
  handleLeave,
  handleView,
  handleShowDeviceList,
  handleShowMicDeviceList,
  handleShowSpeakerDeviceList,
  handleChangeCamera,
  onClickScreenShare,
  handleChangeMic,
  handleChangeSpeaker,
  hasMultipleSources = false,
  showDeviceList = false,
  showMicList = false,
  showSpeakerList = false,
  showSwitchView,
  showScreenShare,
  showEndCall,
  showAudioPreview = false,
  handleClickOutside,
  ...props
}) => {
  const {
    setSelectedWebcam,
    setSelectedMic,
    selectedMic,
    selectedWebcam,
    selectedSpeaker,
    setSelectedSpeaker,
    webcams,
    mics,
    speakers,
    isCameraPermissionAllowed,
    isMicrophonePermissionAllowed,
    setDevices
  } = useMeetingAppContext();
  const isMobile = mobileBreakPoint();
  const permissonAvaialble = useRef();

  useEffect(() => {
    permissonAvaialble.current = {
      isCameraPermissionAllowed,
      isMicrophonePermissionAllowed
    };
  }, [isCameraPermissionAllowed, isMicrophonePermissionAllowed]);

  const errors = useDeviceErrorMessageStore(state => state.errors);
  const listboxRef = useClickOutside(handleClickOutside);

  const { getCameras, getMicrophones, getPlaybackDevices } = useMediaDevice({
    onDeviceChanged
  });

  function onDeviceChanged() {
    setTimeout(() => {
      getCameraDevices();
      getAudioInputDevices();
      getAudioOutputDevices();
    }, 2000);
  }

  useEffect(() => {
    getCameraDevices();
  }, [isCameraPermissionAllowed]);

  useEffect(() => {
    getAudioInputDevices();
    getAudioOutputDevices();
  }, [isMicrophonePermissionAllowed]);

  const getCameraDevices = async () => {
    try {
      if (permissonAvaialble.current?.isCameraPermissionAllowed) {
        const webcams = await getCameras();
        setSelectedWebcam({
          id: webcams[0]?.deviceId,
          label: webcams[0]?.label
        });
        setDevices(devices => {
          return { ...devices, webcams };
        });
      }
    } catch (err) {
      console.log('Error in getting camera devices', err);
    }
  };

  const getAudioInputDevices = async () => {
    try {
      if (permissonAvaialble.current?.isMicrophonePermissionAllowed) {
        const mics = await getMicrophones();
        await setSelectedMic({ id: mics[0]?.deviceId, label: mics[0]?.label });
        console.log('mics', mics);
        setDevices(devices => {
          return { ...devices, mics };
        });
      }
    } catch (err) {
      console.log('Error in getting audio input devices', err);
    }
  };

  const getAudioOutputDevices = async () => {
    try {
      if (permissonAvaialble.current?.isMicrophonePermissionAllowed) {
        const speakers = await getPlaybackDevices();
        await setSelectedSpeaker({
          id: speakers[0]?.deviceId,
          label: speakers[0]?.label
        });
        setDevices(devices => {
          return { ...devices, speakers };
        });
      }
    } catch (err) {
      console.log('Error in getting audio output devices', err);
    }
  };

  const DeviceList = ({ dataLists = [], showLists }) => {
    return (
      <>
        {showLists &&
          dataLists.length > 0 &&
          dataLists.map((k, index) => (
            <div
              key={index}
              className={'source-entry ' + (k.deviceId === selectedWebcam.id ? 'source-entry--selected' : '')}
              onClick={() => {
                setSelectedWebcam(s => ({
                  ...s,
                  id: k?.deviceId,
                  label: k?.label
                }));
                handleChangeCamera(k.deviceId);
              }}
              role="radio"
              aria-checked="true"
            >
              {k.deviceId === selectedWebcam.id && (
                <div className="source-entry-icon">
                  <CheckCircle size={14} color="#31b76a" weight="fill" />
                </div>
              )}

              <div className="source-entry-label">
                <span>{k.label}</span>
              </div>
            </div>
          ))}
      </>
    );
  };

  const MicList = ({ dataLists = [], showLists }) => {
    return (
      <>
        {showLists &&
          dataLists.length > 0 &&
          dataLists.map((k, index) => (
            <div
              key={`mic-${index}`}
              className={'source-entry ' + (k.deviceId === selectedMic.id ? 'source-entry--selected' : '')}
              onClick={() => {
                setSelectedMic(s => ({
                  ...s,
                  label: k?.label,
                  id: k?.deviceId
                }));
                handleChangeMic(k.deviceId);
              }}
              role="radio"
              aria-checked="true"
            >
              {k.deviceId === selectedMic.id && (
                <div className="source-entry-icon">
                  <CheckCircle size={14} color="#31b76a" weight="fill" />
                </div>
              )}

              <div className="source-entry-label">
                <span>{k.label}</span>
              </div>
            </div>
          ))}
      </>
    );
  };

  const SpeakerList = ({ dataLists = [], showLists }) => {
    return (
      <>
        {showLists &&
          dataLists.length > 0 &&
          dataLists.map((k, index) => (
            <div
              key={`speaker-${index}`}
              className={'source-entry ' + (k.deviceId === selectedSpeaker.id ? 'source-entry--selected' : '')}
              onClick={() => {
                setSelectedSpeaker(s => ({
                  ...s,
                  label: k?.label,
                  id: k?.deviceId
                }));

                handleChangeSpeaker(k.deviceId);
              }}
              role="radio"
              aria-checked="true"
            >
              {k.deviceId === selectedSpeaker.id && (
                <div className="source-entry-icon">
                  <CheckCircle size={14} color="#31b76a" weight="fill" />
                </div>
              )}

              <div className="source-entry-label">
                <span>{k.label}</span>
              </div>
            </div>
          ))}
      </>
    );
  };

  return (
    <div className="toolbox toolbox-wrapper" {...props}>
      <div className="toolbox-content" ref={listboxRef}>
        {!isMobile && speakers?.length > 0 && (
          <ToolboxIcon
            onClick={handleShowSpeakerDeviceList}
            ariaLabel="Speakers"
            active={true}
            iconActive="SpeakerHigh"
            hasMultipleSources={hasMultipleSources}
            onDeviceListClick={handleShowSpeakerDeviceList}
          >
            <div className="toolbox-source-list">
              <SpeakerList dataLists={speakers} showLists={showSpeakerList} />
            </div>
          </ToolboxIcon>
        )}
        {showAudioPreview && <AudioPreview micEnabled={micOn} />}

        <ToolboxIcon
          onClick={handleToggleMic}
          ariaLabel="Mute / Unmute"
          active={micOn}
          iconActive="Microphone"
          iconNonActive="MicrophoneSlash"
          withTooltipMessage={errors?.audio}
          hasMultipleSources={hasMultipleSources}
          onDeviceListClick={handleShowMicDeviceList}
        >
          <div className="toolbox-source-list">
            <MicList dataLists={mics} showLists={showMicList} />
          </div>
        </ToolboxIcon>
        <ToolboxIcon
          onClick={handleToggleWebcam}
          ariaLabel="Start / Stop camera"
          active={webcams?.length && webcamOn}
          iconActive="VideoCamera"
          iconNonActive="VideoCameraSlash"
          hasMultipleSources={hasMultipleSources}
          onDeviceListClick={handleShowDeviceList}
        >
          <div className="toolbox-source-list">
            <DeviceList dataLists={webcams} showLists={showDeviceList} />
          </div>
        </ToolboxIcon>

        {showSwitchView && (
          <ToolboxIcon
            onClick={() => handleView(prevCheck => !prevCheck)}
            ariaLabel="Toggle tile view"
            active={showSwitchView}
            toggleIcon={toggleView}
            iconActive="SquaresFour"
          />
        )}

        {/* {showScreenShare && !isMobile && (
          <div className="toolbox-button-container">
            <div className="toolbox-button" role="button">
              <div onClick={onClickScreenShare} className="toolbox-icon">
                <img
                  src="https://img.okadoc.com/photos/block_images/img/icon/screen-share.svg"
                  alt="screen share icon"
                  className="screen-share-icon"
                />
              </div>
            </div>
          </div>
        )} */}

        {showEndCall && (
          <ToolboxIcon
            onClick={handleLeave}
            className="toolbox-button toolbox-button-hangup"
            ariaLabel="Leave the meeting"
            active={showEndCall}
            iconActive="Phone"
          />
        )}
      </div>
    </div>
  );
};

Toolbox.propTypes = {
  handleToggleMic: PropTypes.func.isRequired,
  handleToggleWebcam: PropTypes.func.isRequired
};

export default Toolbox;
