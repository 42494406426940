import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import qs from 'qs';
import { MeetingProvider } from '@videosdk.live/react-sdk';

// Containers
import PreMeeting from '../pre-meeting';
import Meeting from '../video';
import Header from 'containers/header';
import WaitingRoomHeader from 'containers/header/waiting-room-header';
import ChatViewReadOnly from '../video/partial/chat-view-read-only';
import WaitingRoom from '../waiting-room';
import MeetingWrapper from './meeting-wrapper';
import useTracking from 'libs/hooks/useTracking';

// Libs
import useChat from 'libs/hooks/useChat';
import { useGlobalConfigStore } from 'zustandStore';

// Constants
import { STATUS_TELEMEDICINE, RESPONSE_STATUS, APPOINTMENT_STATUS } from '../../libs/constant';
import { useMeetingAppContext } from 'MeetingAppContextDef';

const Main = ({
  appointmentNumber,
  showChat,
  setShowChat,
  setShowInfo,
  showInfo,
  participantType,
  showFullChat,
  setShowFullChat,
  country,
  wlVersion,
  lang,
  isXToken,
  t
}) => {
  // Hooks
  const {
    value: { patient, doctor, telemedicine, appointment, chatHistory, isLoadingChatHistory, errors, isDoctor },
    action
  } = useChat();
  const tracking = useTracking({
    from: 'Main'
  });
  const [showVideo, setShowVideo] = useState(true);
  const [isSessionEnd, setIsSessionEnd] = useState(false);
  // Local State
  const [isUnread, setIsUnread] = useState(false);
  const [isChatOnly, setIsChatOnly] = useState(false);
  const [theToken] = useState('');
  const [theMeetingID] = useState('');
  const [participantName, setParticipanName] = useState('');
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const meetingStarted = useGlobalConfigStore(state => state.meetingStarted);
  const setMeetingStartedStore = useGlobalConfigStore(state => state.setMeetingStarted);
  const isEndSession = useGlobalConfigStore(state => state.isEndSession);
  const [customVideoStream, setCustomVideoStream] = useState(null);

  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const isTestingMode = params?.isTestingMode === 'true';
  const telemedicineStatus = telemedicine?.telemedicineStatus;
  const tokenSdk = telemedicine?.token;
  const meetingId = telemedicine?.meetingId;
  const isAppointmentInvalid =
    (errors.status === RESPONSE_STATUS.INVALID && appointment.status === APPOINTMENT_STATUS.DONE) ||
    errors.status === RESPONSE_STATUS.EXPIRED;

  const { isMicOn, isWebcamOn } = useMeetingAppContext();

  const handleJoinMeeting = () => {
    setIsWaiting(false);
    setMeetingStarted(true);

    tracking.trackEvent('join_room', isDoctor ? 'doctor' : 'patient', {
      micOn: isMicOn,
      webcamOn: isWebcamOn,
      action: 'pre_meeting_join_room'
    });
  };

  const recipientName = isDoctor ? appointment?.patient : appointment?.doctorName;
  const recipientPhoto = isDoctor ? patient?.photo : doctor?.profilePicture;

  const handleMoveToPreMeeting = useCallback(
    async forceRequestRoom => {
      if (forceRequestRoom) {
        await action?.requestRoom({ appointmentNumber });

        return;
      }
      setIsWaiting(false);
      setMeetingStarted(false);

      return;
    },
    [appointmentNumber, action]
  );

  const handleSwitchToChatOnly = () => {
    setIsSessionEnd(true);
    setIsChatOnly(true);
  };

  const goToChatRoom = () => {
    setMeetingStartedStore(true);
    handleJoinMeeting();
    setShowFullChat(true);
    setShowVideo(false);
  };

  useEffect(() => {
    if (isAppointmentInvalid || isChatOnly) {
      action?.getChatHistory({ appointmentNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppointmentInvalid, isChatOnly, appointmentNumber]);

  useEffect(() => {
    if (!isTestingMode) {
      const startTime = moment(appointment.startTime);
      const diffTime = appointment.startTime && startTime.diff(moment(), 'hours', true);
      const isMoreThanCurrentAppointment = Math.ceil(diffTime) > 0;

      const outsideRangeConsultation = diffTime && isMoreThanCurrentAppointment && !isAppointmentInvalid;

      // Outside Range Consultation (~ min 1 hour before consultation)
      if (outsideRangeConsultation) {
        setMeetingStarted(false);
        setIsWaiting(true);
      }

      if (telemedicineStatus === STATUS_TELEMEDICINE.PRE_MEETING || telemedicineStatus === STATUS_TELEMEDICINE.ACTIVE) {
        if (isSkipPreMeeting) {
          goToChatRoom();
          return;
        }
        handleMoveToPreMeeting();
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telemedicineStatus, appointment, isTestingMode, isAppointmentInvalid]);

  const isReadyForMeeting = useMemo(() => {
    let isReadyForMeeting = !!(!isWaiting && tokenSdk && meetingId && !isChatOnly);
    if (isTestingMode) {
      isReadyForMeeting = !isWaiting && telemedicine?.testingToken && telemedicine?.theMeetingID && !isChatOnly;
    }

    return isReadyForMeeting;
  }, [telemedicine, isWaiting, tokenSdk, meetingId, isTestingMode, isChatOnly]);

  const participantId = useMemo(
    () => `${isDoctor ? 'doctor' : 'patient'}::${appointment.userId}::${Math.floor(Date.now() / 1000)}`,
    [appointment.userId, isDoctor]
  );

  const isSkipPreMeeting = useMemo(() => !isDoctor && appointment?.isODDConsultation, [isDoctor, appointment]);

  const preMeetingScreen = useMemo(
    () => (
      <PreMeeting
        onJoinRoom={handleJoinMeeting}
        participantName={participantName}
        setParticipanName={setParticipanName}
        customVideoStream={customVideoStream}
        setCustomVideoStream={setCustomVideoStream}
        setIsSessionEnd={setIsSessionEnd}
        setShowChat={setShowChat}
        setShowVideo={setShowVideo}
        setShowFullChat={setShowFullChat}
        isTestingMode={isTestingMode}
        isDoctor={isDoctor}
        t={t}
        setShowInfo={setShowInfo}
      />
    ),
    [isDoctor, isTestingMode, participantName, setShowChat, setShowFullChat, setShowVideo]
  );

  useEffect(() => {
    if (isEndSession && !isChatOnly) {
      setIsChatOnly(true);
    }
  }, [isEndSession, isChatOnly]);

  return (
    <>
      {!!isWaiting && (
        <>
          <WaitingRoomHeader
            showVideo={showVideo}
            setShowVideo={setShowVideo}
            showChat={showChat}
            setShowChat={setShowChat}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            showFullChat={showFullChat}
            setShowFullChat={setShowFullChat}
            recipientName={recipientName}
            recipientPhoto={recipientPhoto}
            isUnread={isUnread}
            setIsUnread={setIsUnread}
            t={t}
          />
          <WaitingRoom
            goToPreMeeting={handleMoveToPreMeeting}
            isDoctor={appointment?.isDoctor}
            isTestingMode={isTestingMode}
            showInfo={showInfo}
            t={t}
          />
        </>
      )}
      {isReadyForMeeting && meetingStarted && (
        <MeetingProvider
          config={{
            meetingId: isTestingMode ? theMeetingID : meetingId,
            micEnabled: false,
            webcamEnabled: showFullChat ? false : isWebcamOn,
            name: appointment?.participantName,
            participantId,
            customCameraVideoTrack: customVideoStream
          }}
          joinWithoutUserInteraction={true}
          token={theToken || tokenSdk}
        >
          <Header
            isSessionEnd={isSessionEnd}
            showVideo={showVideo}
            setShowVideo={setShowVideo}
            showChat={showChat}
            setShowChat={setShowChat}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            showFullChat={showFullChat}
            setShowFullChat={setShowFullChat}
            recipientName={recipientName}
            recipientPhoto={recipientPhoto}
            isUnread={isUnread}
            setIsUnread={setIsUnread}
            isMeetingStarted={isMeetingStarted}
            t={t}
            preMeetingScreen={preMeetingScreen}
          />
          <MeetingWrapper
            onSwitchChat={handleSwitchToChatOnly}
            showFullChat={showFullChat}
            isMeetingStarted={meetingStarted && !isSessionEnd | !isChatOnly}
          >
            {meetingStarted && (
              <Meeting
                participantType={participantType}
                participantId={participantId}
                showVideo={showVideo}
                showChat={showChat}
                setShowVideo={setShowVideo}
                setShowFullChat={setShowFullChat}
                setShowChat={setShowChat}
                setShowInfo={setShowInfo}
                showInfo={showInfo}
                showFullChat={showFullChat}
                isPatient={!isDoctor}
                country={country}
                lang={lang}
                setIsUnread={setIsUnread}
                wlVersion={wlVersion}
                isXToken={isXToken}
                t={t}
              />
            )}
          </MeetingWrapper>
        </MeetingProvider>
      )}
      {!isSkipPreMeeting && isReadyForMeeting && !meetingStarted && preMeetingScreen}
      {(isAppointmentInvalid || isChatOnly) && (
        <div className="chat chat--readonly">
          <ChatViewReadOnly
            isLoadingChatHistory={isLoadingChatHistory}
            isPatient={!isDoctor}
            messages={chatHistory}
            telemedicineStatus={telemedicineStatus}
          />
        </div>
      )}
    </>
  );
};

export default Main;
