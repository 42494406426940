/* eslint-disable new-cap */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AppointmentAPI from 'libs/api/appointment';
import Loading from 'components/Loading';
import ConsentModal from 'components/organisms/consent-modal';
import CSATModal from 'components/csat';
import Main from './main';
// Module Requests
import useChat from 'libs/hooks/useChat';
import { getQueryParams } from 'libs/url';
import useService from 'libs/hooks/useService';
import useToast from 'libs/hooks/useToast';
import { RESPONSE_STATUS, APPOINTMENT_STATUS } from 'libs/constant';
import isAvailable, { FEATURE_TELEMEDICINE_CONSENT } from 'libs/feature-flags';
import { LoadingWrapper } from './styles';
import ErrorPage from 'containers/error-page';
import { useGlobalConfigStore } from 'zustandStore';
import { useServerEventTelemedicine } from './actions';
import { MeetingAppProvider } from 'MeetingAppContextDef';
import useIsMobileScreen from 'libs/hooks/useIsMobileScreen';

function App() {
  // Local State
  const [isLoading, setLoading] = useState(true);
  const [isLoadingParticipant, setIsLoadingParticipant] = useState(false);
  const [hasErrorNetwork, setHasErrorNetwork] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showFullChat, setShowFullChat] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isOpenConsent, setIsOpenConsent] = useState(false);
  const [consentDetails, setConsentDetails] = useState({});
  const [showCSAT, setShowCSAT] = useState(false);
  const { isShowCSAT, isEndSession, toggleState } = useGlobalConfigStore(state => ({
    isShowCSAT: state.isShowCSAT,
    isEndSession: state.isEndSession,
    toggleState: state.toggleState
  }));

  // Hooks
  const chat = useChat();
  const initCtx = useService();
  const location = useLocation();
  const { t } = useTranslation();
  const { show } = useToast();

  const {
    action,
    value: { appointment, errors, isLoadAppointmentAndRoom, isDoctor }
  } = chat;

  const params = getQueryParams(location);

  const domain = params?.domain;
  const appointmentNumber = params?.id;
  const country = params?.country;
  const lang = params?.lang;
  const wlVersion = params?.wlversion || 0;
  const isXToken = params?.istoken === 'true';

  const hasInitRequest = initCtx?.value?.init?.hasInit;
  const config = initCtx?.value?.init?.config;
  const isTestingMode = params?.isTestingMode === 'true';
  const isMobileScreen = useIsMobileScreen();

  useEffect(() => {
    if (!isMobileScreen) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }, [isMobileScreen]);

  // Initialize Okadoc SSE
  useServerEventTelemedicine();

  const GetAppointmentDetail = useCallback(
    async () => {
      setLoading(true);
      const payload = {
        appointmentNumber
      };

      try {
        await Promise.all([
          action?.getAppointmentDetail(payload),
          action?.requestRoom({ appointmentNumber, isInitialLoad: true })
        ]);
      } catch (error) {
        setHasErrorNetwork(true);
      }

      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setAcceptConsent = async () => {
    const [, response] = await AppointmentAPI.PostAppointmentNeedConfirmConsent({
      appointmentNumber,
      payload: {
        fg_consent: 1
      }
    });

    if (response?.status === 200 && isAvailable([FEATURE_TELEMEDICINE_CONSENT])) {
      setIsOpenConsent(false);
    }
  };

  useEffect(() => {
    if (!isTestingMode && hasInitRequest && appointmentNumber && domain) {
      GetAppointmentDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitRequest, appointmentNumber, domain, isTestingMode]);

  useEffect(() => {
    const fetchActiveParticipant = async () => {
      setIsLoadingParticipant(true);

      const { hasJoined, err } = await action.fetchActiveParticipant(appointmentNumber);

      if (hasJoined || err) {
        show({
          type: 'danger',
          message: err
            ? err?.response
            : t(
                'You are already logged in from one device, please close that session before joining from a second device.'
              )
        });
      }
      setIsLoadingParticipant(false);
    };
    const isAppointmentInvalid =
      (errors.status === RESPONSE_STATUS.INVALID && appointment.status === APPOINTMENT_STATUS.DONE) ||
      errors.status === RESPONSE_STATUS.EXPIRED;

    if (isAvailable([FEATURE_TELEMEDICINE_CONSENT])) {
      const details = {
        fileName: appointment?.telemedicineConsentFileName,
        description: appointment?.telemedicineConsentDescription,
        url: appointment?.telemedicineConsentFileUrl
      };
      setConsentDetails(details);
      setIsOpenConsent(isDoctor ? false : appointment?.fgConsent);
    }

    // If participant is patient and appointment is ODD teleconsultation
    if (!isDoctor && appointment?.isODDConsultation && !isAppointmentInvalid) {
      fetchActiveParticipant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, appointmentNumber, isDoctor]);

  useEffect(() => {
    if (!showCSAT && isEndSession && isShowCSAT) {
      setShowCSAT(true);
    } else if (showCSAT && isEndSession && !isShowCSAT) {
      setShowCSAT(false);
    }
  }, [showCSAT, isEndSession, isShowCSAT]);

  const isLoadingRequest = isTestingMode ? false : isLoadAppointmentAndRoom;

  const toggleCSAT = useCallback(() => {
    setShowCSAT(!showCSAT);
    toggleState({ isShowCSAT: false, showCSATDone: true });
  }, [showCSAT, toggleState]);

  if (hasErrorNetwork) {
    return <ErrorPage />;
  }

  if (isLoadingRequest || isLoading || isLoadingParticipant) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <MeetingAppProvider>
        {!showCSAT && (
          <Main
            appointmentNumber={appointmentNumber}
            // isSessionEnd={isSessionEnd}
            practitionerType={isDoctor}
            // setIsSessionEnd={setIsSessionEnd}
            setShowChat={setShowChat}
            setShowInfo={setShowInfo}
            // setShowVideo={setShowVideo}
            showChat={showChat}
            showInfo={showInfo}
            // showVideo={showVideo}
            showFullChat={showFullChat}
            setShowFullChat={setShowFullChat}
            country={country}
            lang={lang}
            wlVersion={wlVersion}
            isXToken={isXToken}
            t={t}
          />
        )}
        <CSATModal
          show={showCSAT}
          toggle={toggleCSAT}
          consultationCompletedUrl={config?.teleconsultation_completed_url}
        />
        {isAvailable([FEATURE_TELEMEDICINE_CONSENT]) && (
          <ConsentModal
            open={isOpenConsent}
            consent={consentDetails}
            onHide={() => setIsOpenConsent(false)}
            onSubmit={setAcceptConsent}
          />
        )}
      </MeetingAppProvider>
    </>
  );
}

export default App;
