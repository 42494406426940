import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useGlobalConfigStore } from 'zustandStore';
import ToolBox from 'components/Toolbox';
import InfoView from '../video/partial/info-view';
import { PreMeetingGlobalStyle, VideoPreview } from './styles';
import useChat from 'libs/hooks/useChat';
import ConferenceAPI from 'libs/api/conference';
import { useMediaDevice, Constants } from '@videosdk.live/react-sdk';
import useFeatureFlagAPI, {
  DIGITAL_CARE_SETTINGS,
  FEATURE_FLAG_DISABLE_START_WITH_CHAT_ONLY
} from 'libs/hooks/useFeatureFlagAPI';
import { isMobile } from 'libs/okaBrowserCheck';
import useMediaStream from 'libs/hooks/useMediaStream';
import useToast from 'libs/hooks/useToast';
import useTracking from 'libs/hooks/useTracking';

import { VirtualBackgroundProcessor } from '@videosdk.live/videosdk-media-processor-web';
import { getVirtualBackgroundUrl, isUseVirtualBackground } from 'libs/virtual-background';
import NetworkStats from './networkstats';
import { useMeetingAppContext } from 'MeetingAppContextDef';
import useIsMobileScreen from 'libs/hooks/useIsMobileScreen';

const PreMeeting = ({
  onJoinRoom,
  setIsSessionEnd,
  setShowChat = () => {},
  setShowFullChat = () => {},
  setShowVideo = () => {},
  isTestingMode = false,
  setCustomVideoStream
}) => {
  const tracking = useTracking({ from: 'PreMeeting' });
  const trackingPermission = useTracking({ from: 'Permission' });
  const isMobileScreen = useIsMobileScreen();

  const { show } = useToast();
  const { getVideoTrack } = useMediaStream();
  const {
    setIsCameraPermissionAllowed,
    setIsMicrophonePermissionAllowed,
    isCameraPermissionAllowed,
    isMicrophonePermissionAllowed,
    setIsMicOn,
    setIsWebcamOn,
    isWebcamOn,
    selectedWebcam,
    isMicOn,
    setInitialMicOn
  } = useMeetingAppContext();
  const { checkPermissions, requestPermission } = useMediaDevice({});
  const { t } = useTranslation();
  const {
    value: { appointment, doctor, isDoctor },
    action
  } = useChat();

  const isODDConsultation = appointment?.isODDConsultation ?? false;

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  const [customVideoTrack, setCustomVideoTrack] = useState(null);

  const [toggleShowDeviceList, setToggleShowDeviceList] = useState(false);
  const [toggleShowMicDeviceList, setToggleShowMicDeviceList] = useState(false);
  const [toggleShowSpeakerDeviceList, setToggleShowSpeakerDeviceList] = useState(false);

  const webcamRef = useRef();
  const micRef = useRef();
  const videoPlayerRefe = useRef();
  const videoTrackRef = useRef();

  useEffect(() => {
    webcamRef.current = isWebcamOn;
  }, [isWebcamOn]);

  useEffect(() => {
    micRef.current = isMicOn;
    setInitialMicOn(isMicOn);
  }, [isMicOn]);

  const videoProcessor = useRef();
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const setMeetingStarted = useGlobalConfigStore(state => state.setMeetingStarted);

  const isDisabledStartWithChatOnly = useFeatureFlagAPI(
    FEATURE_FLAG_DISABLE_START_WITH_CHAT_ONLY,
    DIGITAL_CARE_SETTINGS
  );

  const showGeneralAction = (isODDConsultation && isDoctor) || !isODDConsultation;

  useEffect(() => {
    if (selectedWebcam) {
      handleChangeCamera(selectedWebcam.id);
    }
  }, [selectedWebcam]);

  const processVideoStream = async stream => {
    try {
      if (isUseVirtualBackground(isDoctor) && !videoProcessor.current.ready) {
        await videoProcessor.current.init();
      }

      // default background from window.location
      if (isUseVirtualBackground(isDoctor)) {
        const config = {
          type: 'image',
          imageUrl: getVirtualBackgroundUrl()
        };
        const processedStream = await videoProcessor.current.start(stream, config);
        return processedStream;
      } else {
        return stream;
      }
    } catch (err) {
      throw new Error('Failed to process video stream');
    }
  };

  useEffect(() => {
    checkMediaPermission();
    videoProcessor.current = new VirtualBackgroundProcessor();
    return () => {};
  }, []);

  useEffect(() => {
    if (isWebcamOn) {
      // Close the existing video track if there's a new one
      if (videoTrackRef.current && videoTrackRef.current !== customVideoTrack) {
        videoTrackRef.current.stop(); // Stop the existing video track
      }

      videoTrackRef.current = customVideoTrack;

      const isPlaying =
        videoPlayerRefe.current.currentTime > 0 &&
        !videoPlayerRefe.current.paused &&
        !videoPlayerRefe.current.ended &&
        videoPlayerRefe.current.readyState > videoPlayerRefe.current.HAVE_CURRENT_DATA;

      if (customVideoTrack) {
        const videoSrcObject = new MediaStream([customVideoTrack]);

        if (videoPlayerRefe.current) {
          videoPlayerRefe.current.srcObject = videoSrcObject;
          if (videoPlayerRefe.current.pause && !isPlaying) {
            videoPlayerRefe.current.play().catch(error => console.log('error', error));
          }
        }
      } else {
        if (videoPlayerRefe.current) {
          videoPlayerRefe.current.srcObject = null;
        }
      }
    }
  }, [isWebcamOn, customVideoTrack]);

  const checkMediaPermission = async () => {
    try {
      const checkAudioVideoPermission = await checkPermissions();
      const cameraPermissionAllowed = checkAudioVideoPermission.get(Constants.permission.VIDEO);
      const microphonePermissionAllowed = checkAudioVideoPermission.get(Constants.permission.AUDIO);

      setIsCameraPermissionAllowed(cameraPermissionAllowed);
      setIsMicrophonePermissionAllowed(microphonePermissionAllowed);

      if (microphonePermissionAllowed) {
        setIsMicOn(true);
      } else {
        await trackingPermission.trackEvent('request_permission', isDoctor ? 'doctor' : 'patient', {
          request_permission_from: 'telemedicine-web',
          video_permission: false,
          audio_permission: true,
          is_requesting: true
        });
        await requestAudioVideoPermission(Constants.permission.AUDIO);
      }
      if (cameraPermissionAllowed) {
        setIsWebcamOn(true);
        getDefaultMediaTracks({ mic: false, webcam: true });
      } else {
        await trackingPermission.trackEvent('request_permission', isDoctor ? 'doctor' : 'patient', {
          request_permission_from: 'telemedicine-web',
          video_permission: true,
          audio_permission: false,
          is_requesting: true
        });
        await requestAudioVideoPermission(Constants.permission.VIDEO);
      }
    } catch (error) {
      // For firefox, it will request audio and video simultaneously.
      await trackingPermission.trackEvent('request_permission', isDoctor ? 'doctor' : 'patient', {
        request_permission_from: 'telemedicine-web',
        video_permission: true,
        audio_permission: true,
        is_requesting: true
      });
      await requestAudioVideoPermission();
      console.log(error);
    }
  };

  const requestAudioVideoPermission = async mediaType => {
    try {
      const permission = await requestPermission(mediaType);

      // For Video
      if (isFirefox) {
        const isVideoAllowed = permission.get('video');
        setIsCameraPermissionAllowed(isVideoAllowed);
        if (isVideoAllowed) {
          setIsWebcamOn(true);
          await getDefaultMediaTracks({ mic: false, webcam: true });
        }
      }

      // For Audio
      if (isFirefox) {
        const isAudioAllowed = permission.get('audio');
        setIsMicrophonePermissionAllowed(isAudioAllowed);
        if (isAudioAllowed) {
          setIsMicOn(true);
        }
      }

      if (mediaType === Constants.permission.AUDIO) {
        const isAudioAllowed = permission.get(Constants.permission.AUDIO);
        setIsMicrophonePermissionAllowed(isAudioAllowed);
        if (isAudioAllowed) {
          setIsMicOn(true);
        }
      }

      if (mediaType === Constants.permission.VIDEO) {
        const isVideoAllowed = permission.get(Constants.permission.VIDEO);
        setIsCameraPermissionAllowed(isVideoAllowed);
        if (isVideoAllowed) {
          setIsWebcamOn(true);
          await getDefaultMediaTracks({ mic: false, webcam: true });
        }
      }
    } catch (ex) {
      console.log('Error in requestPermission', ex);
    }
  };

  const getDefaultMediaTracks = async ({ mic, webcam }) => {
    if (webcam) {
      const cameraConfig = {
        optimizationMode: 'motion',
        cameraId: selectedWebcam.id,
        encoderConfig: isDoctor ? 'h540p_w720p' : 'h720p_w1280p',
        multiStream: isDoctor ? true : false,
        facingMode: isMobile ? 'user' : 'environment'
      };
      const stream = await getVideoTrack(cameraConfig);
      const processedStream = await processVideoStream(stream);
      setCustomVideoStream(processedStream);
      const videoTracks = processedStream?.getVideoTracks();
      const videoTrack = videoTracks?.length ? videoTracks[0] : null;
      setCustomVideoTrack(videoTrack);
    }
  };

  const handleChangeCamera = async deviceId => {
    if (isWebcamOn && deviceId) {
      const currentvideoTrack = videoTrackRef.current;
      if (currentvideoTrack) {
        currentvideoTrack.stop();
      }

      const cameraConfig = {
        optimizationMode: 'motion',
        cameraId: deviceId,
        encoderConfig: isDoctor ? 'h540p_w720p' : 'h720p_w1280p',
        multiStream: isDoctor ? true : false,
        facingMode: isMobile ? 'user' : 'environment'
      };
      const stream = await getVideoTrack(cameraConfig);
      setCustomVideoStream(stream);
      const videoTracks = stream?.getVideoTracks();
      const videoTrack = videoTracks?.length ? videoTracks[0] : null;
      setCustomVideoTrack(videoTrack);

      setToggleShowDeviceList(false);
      setToggleShowMicDeviceList(false);
      setToggleShowSpeakerDeviceList(false);
    }
  };

  const handleChangeMic = async deviceId => {
    if (isMicOn && deviceId) {
      setToggleShowDeviceList(false);
      setToggleShowMicDeviceList(false);
      setToggleShowSpeakerDeviceList(false);
    }
  };

  const changeSpeaker = () => {
    setToggleShowDeviceList(false);
    setToggleShowMicDeviceList(false);
    setToggleShowSpeakerDeviceList(false);
  };
  const handleToggleMic = () => {
    if (!isMicrophonePermissionAllowed) {
      show({
        type: 'danger',
        message: t(
          'Microphone access is blocked. Please check the microphone permission for this app via device setting'
        )
      });
      return;
    }

    if (isMicOn) {
      setIsMicOn(false);
    } else {
      setIsMicOn(true);
    }
    tracking.trackEvent(!isMicOn ? 'enable_audio' : 'disable_audio', isDoctor ? 'doctor' : 'patient', {
      micOn: !isMicOn,
      webcamOn: isWebcamOn,
      action: 'pre_meeting_toolbox'
    });
  };

  const handleToggleLocalWebcam = () => {
    if (!isCameraPermissionAllowed) {
      show({
        type: 'danger',
        message: t('Camera access is blocked. Please check the camera permission for this app via device setting')
      });
      return;
    }
    const videoTrack = videoTrackRef.current;

    if (isWebcamOn) {
      if (videoTrack) {
        videoTrack.stop();
        setCustomVideoTrack(null);
        setCustomVideoStream(null);
        setIsWebcamOn(false);
      }
    } else {
      getDefaultMediaTracks({ mic: false, webcam: true });
      setIsWebcamOn(true);
    }

    tracking.trackEvent(!isWebcamOn ? 'enable_video' : 'disable_video', isDoctor ? 'doctor' : 'patient', {
      micOn: isMicOn,
      webcamOn: !isWebcamOn,
      action: 'pre_meeting_toolbox'
    });
  };

  const hasJoinedMultipleDevice = async () => {
    const payload = { appointmentNumber: appointment?.appointmentNumber };
    // eslint-disable-next-line new-cap
    const [err, response] = await ConferenceAPI.GetActiveParticipant(payload);

    // find participant match with current user
    const hasJoined = response?.data?.data?.find(({ user_id: userId = '' }) =>
      userId.includes(isDoctor ? 'doctor' : 'patient')
    );

    return { hasJoined, err };
  };

  const handleJoinRoom = async e => {
    e.preventDefault();
    // await videoProcessor.current.stop();

    if ((isDoctor && isODDConsultation) || !isODDConsultation) {
      const { hasJoined, err } = await hasJoinedMultipleDevice();
      // Show toast error when failed to get participant active
      if (hasJoined || err) {
        show({
          type: 'danger',
          message: err
            ? err?.response
            : t(
                'You are already logged in from one device, please close that session before joining from a second device.'
              )
        });

        return;
      }
    }

    if (!isDoctor && isODDConsultation) {
      setShowChat(false);
      setShowFullChat(false);

      toggleState({ isPatientJoinTheCall: true });

      action?.setIsShowAppDrawer(false);
    }
    const data = {
      appointmentNumber: appointment.appointmentNumber,
      message: {
        format: 'video_start',
        text: `${appointment.participantName}`,
        message: `${appointment.participantName}`,
        time: new Date().toISOString()
      }
    };
    action.saveChat(data);

    setMeetingStarted(true);
    setShowVideo(true);

    onJoinRoom();
    // setIsSessionEnd(false);
  };

  const handleJoinChat = async e => {
    e.preventDefault();
    const { hasJoined, err } = await hasJoinedMultipleDevice();
    // Show toast error when failed to get participant active
    if (hasJoined || err) {
      show({
        type: 'danger',
        message: err
          ? err?.response
          : t(
              'You are already logged in from one device, please close that session before joining from a second device.'
            )
      });

      return;
    }

    setShowVideo(false);

    // If switched to chat dispose camera and audio tracks
    const videoTrack = videoTrackRef.current;
    if (isWebcamOn) {
      if (videoTrack) {
        videoTrack.stop();
        setCustomVideoTrack(null);
        setCustomVideoStream(null);
        setIsWebcamOn(false);
      }
    }
    if (isMicOn) {
      setIsMicOn(false);
    }

    onJoinRoom();
    setIsSessionEnd(false);
    setMeetingStarted(true);
    if (isMobileScreen) {
      setShowChat(true);
    }
    setShowFullChat(true);
  };
  const handleShowDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => !show);
    setToggleShowMicDeviceList(false);
    setToggleShowSpeakerDeviceList(false);
  };

  const handleShowMicDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(false);
    setToggleShowMicDeviceList(show => !show);
    setToggleShowSpeakerDeviceList(false);
  };

  const handleShowSpeakerDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(false);
    setToggleShowSpeakerDeviceList(show => !show);
    setToggleShowMicDeviceList(false);
  };

  const handleClickOutside = () => {
    setToggleShowDeviceList(false);
    setToggleShowMicDeviceList(false);
    setToggleShowSpeakerDeviceList(false);
  };

  return (
    <>
      <PreMeetingGlobalStyle isDoctor={isDoctor} isODDConsultation={isODDConsultation} />
      <div className="video-embed active">
        <div id="lobby-screen" className="premeeting-screen">
          <div className="premeeting-screen-header-container">
            <div id="header-title-container" className="content">
              <h2 className="title">
                {isODDConsultation && !isDoctor
                  ? t('Ready to start the video call?')
                  : t('This consultation is ready to start')}
              </h2>
            </div>
            {isODDConsultation && !isDoctor && (
              <div className="title-extra">{t('{{doctor}} is already in the call', { doctor: doctor?.fullName })}</div>
            )}
          </div>

          <div className="premeeting-screen-preview-container">
            <VideoPreview id="preview" className="content">
              <video autoPlay playsInline muted ref={videoPlayerRefe} controls={false} className="video-pre-meeting" />
              <div className="preview-caption-text">{t('Video Preview')}</div>
              <NetworkStats />
            </VideoPreview>

            <div id="preview-navigation" className="content">
              <div className="prejoin-button-container">
                <button className="button green action-btn" onClick={handleJoinRoom}>
                  {showGeneralAction && (
                    <img
                      src="https://img.okadoc.com/photos/block_images/img/icon/videocam.svg"
                      className="icon-button"
                      alt="preview video"
                    />
                  )}
                  {isODDConsultation && !isDoctor ? t('Start Call') : t('Start with video')}
                </button>
              </div>
              {!isDisabledStartWithChatOnly && showGeneralAction && (
                <div className="prejoin-chat-button">
                  <p className="prejoin-chat-button__text">
                    {t('Or you can')}
                    <span className="prejoin-chat-button__action" onClick={handleJoinChat}>
                      <img
                        src="https://img.okadoc.com/photos/block_images/img/icon/ic-chatbox.svg"
                        alt="chatbox icon"
                      />
                      {t('Start with chat only')}
                    </span>
                  </p>
                </div>
              )}
              <ToolBox
                showSwitchView={false}
                showEndCall={false}
                hasMultipleSources
                showAudioPreview
                handleShowDeviceList={handleShowDeviceList}
                showDeviceList={toggleShowDeviceList}
                handleChangeCamera={handleChangeCamera}
                handleToggleWebcam={handleToggleLocalWebcam}
                webcamOn={isWebcamOn}
                handleShowMicDeviceList={handleShowMicDeviceList}
                handleShowSpeakerDeviceList={handleShowSpeakerDeviceList}
                showMicList={toggleShowMicDeviceList}
                handleChangeMic={handleChangeMic}
                handleToggleMic={handleToggleMic}
                showSpeakerList={toggleShowSpeakerDeviceList}
                micOn={isMicOn}
                handleClickOutside={handleClickOutside}
                handleChangeSpeaker={changeSpeaker}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classnames('sidebar', 'chat--sidebar', { hide: true })}>
        <InfoView t={t} isTestingMode={isTestingMode} />
      </div>
    </>
  );
};

PreMeeting.propTypes = {
  onJoinRoom: PropTypes.func,
  setShowFullChat: PropTypes.func,
  setIsSessionEnd: PropTypes.func,
  setShowVideo: PropTypes.func,
  setShowChat: PropTypes.func,
  isTestingMode: PropTypes.bool,
  showInfo: PropTypes.bool,
  micOn: PropTypes.bool,
  webcamOn: PropTypes.bool
};

export default PreMeeting;
