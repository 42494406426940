import { useState, useEffect } from 'react';

const useIsMobileScreen = (breakpoint = 768) => {
  const mobileBreakPoint = () => window.innerWidth <= breakpoint;

  const [isMobileScreen, setIsMobileScreen] = useState(mobileBreakPoint());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(mobileBreakPoint());
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return isMobileScreen;
};

export default useIsMobileScreen;
