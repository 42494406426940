import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as AudioPreviewMeter } from 'assets/images/audio-preview-meter.svg';
import { Wrapper } from './styles';
import { useMeetingAppContext } from 'MeetingAppContextDef';
import { createMicrophoneAudioTrack } from '@videosdk.live/react-sdk';

const AudioPreview = ({ micEnabled }) => {
  const { selectedMic } = useMeetingAppContext();

  const [volumeLevel, setVolumeLevel] = useState(-1);
  const volumeInterval = useRef();
  const audioTracksRef = useRef();
  const volumeCallback = useRef();

  const analyseAudio = async audioTrack => {
    try {
      const audioStream = new MediaStream([audioTrack]);
      const audioContext = new AudioContext();
      const audioSource = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 512;
      analyser.minDecibels = -127;
      analyser.maxDecibels = 0;
      analyser.smoothingTimeConstant = 0.4;
      audioSource.connect(analyser);
      const volumes = new Uint8Array(analyser.frequencyBinCount);
      volumeCallback.current = () => {
        analyser.getByteFrequencyData(volumes);
        let volumeSum = 0;
        for (const volume of volumes) {
          volumeSum += volume;
        }
        const averageVolume = volumeSum / volumes.length;
        setVolumeLevel(Math.floor((averageVolume * 100) / 127 / 7));
      };
    } catch (e) {
      console.error('Failed to initialize volume visualizer', e);
    }
  };

  const reset = () => {
    volumeCallback.current = null;
    clearInterval(volumeInterval.current);
    volumeInterval.current = null;
    setVolumeLevel(-1);
  };

  useEffect(() => {
    const initAudio = async deviceId => {
      const stream = await createMicrophoneAudioTrack({
        microphoneId: deviceId,
        noiseConfig: {
          echoCancellation: true
        }
      });

      const audioTracks = stream?.getAudioTracks();
      audioTracksRef.current = audioTracks;
      const audioTrack = audioTracks?.length ? audioTracks[0] : null;
      analyseAudio(audioTrack);
      if (volumeCallback.current && !volumeInterval.current) {
        volumeInterval.current = setInterval(volumeCallback.current, 100);
      }
    };
    reset();
    if (micEnabled && selectedMic.id) {
      initAudio(selectedMic.id);
    }
    return () => {
      clearInterval(volumeInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMic.id, micEnabled]);

  useEffect(() => {
    if (micEnabled) {
      audioTracksRef?.current?.forEach(track => {
        track.enabled = true;
      });
    } else {
      audioTracksRef?.current?.forEach(track => {
        track.stop();
      });
    }
  }, [audioTracksRef.current, micEnabled]);

  useEffect(() => {
    return () => {
      audioTracksRef?.current?.forEach(track => {
        track.stop();
      });
    };
  }, []);

  return (
    <Wrapper className="d-flex align-items-center" level={volumeLevel}>
      <AudioPreviewMeter />
    </Wrapper>
  );
};

export default AudioPreview;
