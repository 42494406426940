import ReactPlayer from 'react-player';
import { forwardRef, memo } from 'react';

const VideoComponent = forwardRef((props, ref) => (
  <ReactPlayer
    playsinline // very very imp prop
    playIcon={<></>}
    //
    pip={false}
    light={false}
    controls={false}
    muted={true}
    playing={true}
    {...props}
    ref={ref}
  />
));

const compareToProps = (prev, next) => {
  if (prev.url === next.url) {
    return true;
  }

  return false;
};

export default memo(VideoComponent, compareToProps);
