import { useContext, createContext, useState } from 'react';

export const MeetingAppContext = createContext();

export const useMeetingAppContext = () => useContext(MeetingAppContext);

export const MeetingAppProvider = ({ children }) => {
  const [isMicOn, setIsMicOn] = useState(false);
  const [isWebcamOn, setIsWebcamOn] = useState(false);
  const [selectedMic, setSelectedMic] = useState({ id: null, label: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null, label: null });
  const [selectedSpeaker, setSelectedSpeaker] = useState({ id: null, label: null });
  const [isCameraPermissionAllowed, setIsCameraPermissionAllowed] = useState(null);
  const [isMicrophonePermissionAllowed, setIsMicrophonePermissionAllowed] = useState(null);
  const [toggledStates, setToggledStates] = useState({ webcamOn: false, micOn: false });
  const [remoteStreamAvailable, setRemoteStreamAvailable] = useState({
    audio: false,
    video: false
  });
  const [initialMicOn, setInitialMicOn] = useState(false);
  const [{ webcams, mics, speakers }, setDevices] = useState({
    webcams: [],
    mics: [],
    speakers: []
  });

  return (
    <MeetingAppContext.Provider
      value={{
        // states
        selectedMic,
        selectedWebcam,
        selectedSpeaker,
        isCameraPermissionAllowed,
        isMicrophonePermissionAllowed,
        isMicOn,
        isWebcamOn,
        webcams,
        mics,
        speakers,
        toggledStates,
        remoteStreamAvailable,
        initialMicOn,

        // setters
        setSelectedMic,
        setSelectedWebcam,
        setSelectedSpeaker,
        setIsCameraPermissionAllowed,
        setIsMicrophonePermissionAllowed,
        setIsMicOn,
        setIsWebcamOn,
        setDevices,
        setToggledStates,
        setRemoteStreamAvailable,
        setInitialMicOn
      }}
    >
      {children}
    </MeetingAppContext.Provider>
  );
};
