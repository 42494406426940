import { create } from 'zustand';
import { CONSULTATION_STATUS } from 'libs/constant';

const useGlobalConfigStore = create(set => ({
  isEndSession: false,
  networkBarEnabled: true,
  meetingStarted: false,
  isShowAppDrawerUpload: false,
  isShowExamModal: false,
  isExamFromAlert: false,
  selectedExamReport: {},
  isPatientJoinTheCall: false,
  newExamReportAlert: {},
  appToken: '',
  participantStatus: { practitioner: CONSULTATION_STATUS.YET_TO_JOIN, patient: CONSULTATION_STATUS.YET_TO_JOIN },
  isShowCSAT: false,
  showCSATDone: false,
  appointmentNumber: '',
  setAppointmentNumber: appointmentNumber => set(() => ({ appointmentNumber })),
  setIsEndSession: isEndSession => set(() => ({ isEndSession })),
  toggleState: updatedState => set(state => ({ ...state, ...updatedState })),
  setNetworkBarEnabled: networkBarEnabled => set(() => ({ networkBarEnabled })),
  setMeetingStarted: meetingStarted => set(() => ({ meetingStarted })),
  setIsShowAppDrawerUpload: value => set(() => ({ isShowAppDrawerUpload: value })),
  setIsExamFromAlert: value => set(() => ({ isExamFromAlert: value })),
  setIsShowExamModal: value => set(() => ({ isShowExamModal: value })),
  setNewExamReportAlert: value => set(() => ({ newExamReportAlert: value })),
  setSelectedExamReport: selectedExamReport => set({ selectedExamReport }),
  setAppToken: appToken => set({ appToken })
}));

export default useGlobalConfigStore;
