import ConferenceAPI from 'libs/api/conference';
import { getQueryParams } from 'libs/url';
import { useMeetingAppContext } from 'MeetingAppContextDef';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @typedef {Object} TrackingParams
 * @property {string} from
 */

/**
 * @typedef {Object} TrackingResponse
 * @property {Function} trackEvent
 */

/**
 * useTracking hooks
 * @param {TrackingParams} param0
 * @return {TrackingResponse}
 */
const useTracking = ({ from = 'useTracking' }) => {
  const location = useLocation();
  const params = getQueryParams(location);
  const {
    selectedWebcam,
    selectedMic,
    selectedSpeaker,
    mics,
    webcams,
    speakers,
    isCameraPermissionAllowed,
    isMicrophonePermissionAllowed,
    isMicOn,
    isWebcamOn,
    remoteStreamAvailable
  } = useMeetingAppContext();

  const appointmentNumber = params?.id;

  const trackCapabilities = useCallback(
    (eventName, participantType, { micOn, webcamOn, ...restEvent }) => {
      let trackingData = {};

      trackingData = {
        appointment_number: appointmentNumber,
        event_name: eventName,
        participant: participantType,
        state: {
          local_video_captured: isWebcamOn,
          local_audio_captured: isMicOn,
          remote_mic_stream_available: remoteStreamAvailable.audio,
          remote_webcam_stream_available: remoteStreamAvailable.video,
          audio_current_state: typeof micOn === 'boolean' ? micOn : isMicOn,
          video_current_state: typeof webcamOn === 'boolean' ? webcamOn : isWebcamOn,
          available_mic_devices: mics,
          available_webcam_devices: webcams,
          available_speaker_devices: speakers,
          selectedMicDevice: selectedMic,
          selectedWebcamDevice: selectedWebcam,
          selectedSpeakerDevice: selectedSpeaker,
          audio_permission: isMicrophonePermissionAllowed,
          video_permission: isCameraPermissionAllowed,
          ...restEvent
        },
        event_timestamp: new Date().toISOString()
      };
      return ConferenceAPI.trackEvent(trackingData);
    },
    [appointmentNumber, isMicOn, isWebcamOn]
  );

  return {
    trackEvent: async (eventName, participantType, { micOn, webcamOn, ...restEvent }) => {
      return trackCapabilities(eventName, participantType, { micOn, webcamOn, ...restEvent });
    }
  };
};

export default useTracking;
